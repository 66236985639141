<template>
   <div id="box">
      <div id="from">
         <h2>书云心理健康修改系统</h2>
         <el-form ref="form"
                  :model="user"
                  label-width="80px">
            <el-input v-model="user.username"
                      placeholder="请输入用户名"
                      class="zh"></el-input>
            <el-input v-model="user.password"
                      placeholder="请输入旧密码"
                      show-password></el-input>
            <el-input v-model="user.n_password"
                      placeholder="请输入新密码"
                      show-password
                      class="input3"></el-input>
            <el-button type="primary"
                       @click="update">修改密码</el-button>
         </el-form>
      </div>
   </div>
</template>

<script>
import { Message } from "element-ui";
import api from "../../http/api";

export default {
   data () {
      return {
         user: { username: "admin", password: "", n_password: "" },
      };
   },
   methods: {
      async update () {
         const data = await api.login.update(this.user);
         // console.log("修改密码", data);
         if (data.data.data == true) {
            Message.success("密码修改成功");

            this.$router.replace("/login");
         } else {
            Message.error("请输入正确的旧密码");
         }
      },
   },
};
</script>

<style lang='scss' scoped>
#box {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   margin: 0;
   padding: 0;

   background-image: url("../../assets/img/登录背景图2.png");
   #from {
      width: 400px;
      height: 300px;
      padding: 20px;
      margin: 10px;
      text-align: center;
      background-color: white;
      border-radius: 15px;
      opacity: 0.8;
      .input3 {
         margin-top: 20px;
      }
      h2 {
         margin: 20px;
         font-size: 24px;
      }
      .zh {
         margin-bottom: 25px;
      }
      button {
         margin-top: 25px;
      }
   }
}
</style>

